import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled, { keyframes } from 'styled-components'
import { useWeb3Context } from 'hooks/useWeb3Context'
import { NFTData } from 'state/user'
import { useNFT, useStakedNFT, useBalances, useNFTUserData } from 'state/user/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { callClaim } from 'utils/calls'
import Header from './components/Header'
import ActionContainer from './components/ActionContainer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../index.css';
import CoinBackground from "assets/images/coin.png";
import { Link } from 'react-router-dom'

const StyledWrapper = styled.div`
  margin-bottom: 70px;
`

const StyledTitle = styled.div`
  font-family: "Adobe Garamond Pro Regular";
  font-size: 2.8rem;
  text-transform:uppercase;
  letter-spacing: 3.6px;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #ca993b;
`

const StyledContainers = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap:10px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const StyledActionArea = styled.div`
  margin-top: 10px;
  width: 98%;
`

const StyledBalanceTitle = styled.div`
  font-size: 25px;
`

const StyledBalanceContainer = styled.div`
  max-width: 1200px;
  padding: 20px;
  border: 1px solid #ca993b;
  margin-top: 30px;
  box-shadow: 0px 0px 50px black;
`

const StyledBalanceArea = styled.div`
  display: flex;
  justify-content: space-between;  
`

const StyledBalanceInfo = styled.div<{width: string, type: string}>`
  width: ${({ width }) => width};
  margin: 0 5px;
  padding: 10px 20px;
  cursor: ${({ type }) => type === 'button' ? 'pointer' : 'normal'};
`

const StyledBalanceInfoTitle = styled.div`
  font-family: "Adobe Garamond Pro Regular";
  font-size: 27px;
  color:#ca993b;
`

const StyledValue = styled.div`
  font-size: 23px;
`

const StyledDescription = styled.div`
  color: white;
  font-size: 30px;
  font-family: "SF Pro";
  font-weight: 200;
`

const StyledButton = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "SF Pro";
  font-size: 25px;
  font-weight: 600;
  fill: #141414;
  color: #141414;
  background-color: #ca993b;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ca993b;
  padding: 11px 20px 11px 20px;
  cursor: pointer;
  :hover {
		background-color: #231F16;
    color: #ca993b;
		cursor: pointer;
	}
  
`
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotateImage = styled.img`
  animation: ${rotate} 2s linear infinite;
  width: 23px;
  margin-top: 7px;
`
interface BalanceInfoProps { 
  title: string,
  value: string,
  width?: string,
  as?: string,
  event?: () => void
}

const Staking = () => {
  const web3Context = useWeb3Context()
  const [pendingClaim, setPendingClaim] = useState(false)

  const [reload, setReload] = useState(false)

  useNFTUserData(web3Context?.account, reload)
  
  const nftsOnWallet: NFTData[] = useNFT()
  const nftsOnStaking: NFTData[] = useStakedNFT()
  const balance = useBalances()

  const handleClaim = async () => {
    setPendingClaim(true)
    try {
      if (web3Context?.provider && web3Context?.account) {
        await callClaim(web3Context?.provider, web3Context?.account)
        setReload(!reload)
      } else {
        toast.warn('Check the wallet connection.', {
          position: "top-center",
          theme: "dark",
          });
        }
    } catch (error) {
    }
    setPendingClaim(false)
  }

  const BalanceInfo: React.FC<BalanceInfoProps> = ({ title, value, width = 'auto', as = 'text', event }) => {
    return (
      <div className=' w-full lg:w-96  flex items-center justify-center flex-col' onClick={event ? event : () => { }} >
        <div className=' text-center font_ad w-full bg-pr text-2xl lg:text-3xl text-black font-medium py-2 '>{title}</div>
        <div className=' text-center py-2  text-2xl lg:text-3xl'>{value}</div>
      </div>
    )
  }

  return (
    <div className=" w-full ">
      <ToastContainer />
      <div className=' w-full hero relative' style={{ paddingRight: "5%" }}  >
        <Header />
        <div className='  absolute top-40 lg:top-0 left-0 w-full h-full z-10 bg_overlay'>

        </div>
        <div className=' w-full h-full '>
          <img src="/images/hero.jpg" style={{ borderRight: "1px solid #ca993b", }} className=' w-full h-full object-cover' alt="" />
          <div className=' top-40 lg:top-0 absolute left-0 pr-5 w-full h-full z-20 flex items-start flex-col lg:justify-center'>
            <h1 style={{ color: "#ca993b", paddingTop:"10%", paddingLeft: "5%", letterSpacing: "3.6px" }} className=' text-xl  lg:text-5xl font_ad  pb-3 border-b border-pr  '>CRYPTO PLATYPUS NFT STAKING PLATFORM</h1>
            <p style={{ paddingLeft: "5%" }} className=' text-white pt-4 lg:w-3/6  text-base lg:text-4xl font-light'>Tier 1 (0-30 days)         - 1x rewards from pool</p>
            <p style={{ paddingLeft: "5%"  }} className=' text-white pt-4 lg:w-3/6  text-base lg:text-4xl font-light'>Tier 2 (31-100 days)     - 1.25x rewards from pool</p>
            <p style={{ paddingLeft: "5%"  }} className=' text-white pt-4 lg:w-3/6  text-base lg:text-4xl font-light'>Tier 3 (101-180 days)   - 1.5x rewards from pool</p>
            <p style={{ paddingLeft: "5%"  }} className=' text-white pt-4 lg:w-3/6  text-base lg:text-4xl font-light'>Tier 4 (181- 365 days) - 2x rewards from pool</p>
            <p style={{ paddingLeft: "5%"  }} className=' text-white pt-4 lg:w-3/6  text-base lg:text-4xl font-light'>Tier 5 (1yr +)                 - 3x rewards from pool</p>
          </div>
        </div>


      </div>



      <div className=' w-full border-b border-pr mb-10 ' style={{ padding: "0 5%" }}>
        <div className=' w-full flex items-center relative  justify-center pb-20 lg:border-t lg:border-l border-pr' >
          <div className='  items-center justify-around flex-col hidden lg:flex' style={{ width: "5%", gap: "500px" }}>
            <div style={{ height: "1px", width: "100%" }} className=' bg-pr'>

            </div>
            <div style={{ height: "1px", width: "100%" }} className=' bg-pr'>

            </div>
          </div>
          <div className="w-full relative z-50">


            <div className=' w-full relative h-full lg:h-96 z-30' style={{ margin: "5% 0", border: "1px solid #ca993b", backgroundImage: "url(/images/second.jpg)" }}>

              {/* <img src="/images/second.jpg" style={{ opacity: "22%", }} className=' w-full h-full object-cover relative z-10' alt="" /> */}

              <div className=' w-full h-full relative z-20 top-0 left-0 bg-transpa '>
                <div className=' h-full w-full py-10'>
                  <div className=' flex items-center justify-center px-2 flex-col lg:flex-row w-full' style={{ justifyContent: "center",  gap: "50px" }}>
                    <div className=' w-full lg:w-auto' style={{ border: "1px solid #ca993b", background: "#141414" }}>
                      <BalanceInfo title="PTC Claimable" value={getFullDisplayBalance(new BigNumber(balance.pendingReward))} />
                    </div>
                    <div className=' w-full lg:w-auto' style={{ border: "1px solid #ca993b", background: "#141414" }}>
                      <BalanceInfo title="PTC Balance" value={getFullDisplayBalance(new BigNumber(balance.rewardBalance))} />
                    </div>

                  </div>

                  <div className=' flex items-center justify-center px-2 flex-col lg:flex-row w-full gap-10 mt-9'>
                    <div className=' w-full lg:w-auto' style={{ border: "1px solid #ca993b", background: "#141414" }}>
                      <BalanceInfo title="Crypto Platypus NFT Balance" value={(nftsOnWallet.length + nftsOnStaking.length).toString()} width="100%" />
                    </div>

                    <div className=' w-full lg:w-auto' style={{ border: "1px solid #ca993b", background: "#141414" }}>
                      <BalanceInfo title="Not Staked" value={nftsOnWallet.length.toString()} width="100%" />
                    </div>

                    <div className=' w-full lg:w-auto' style={{ border: "1px solid #ca993b", background: "#141414" }}>
                      <BalanceInfo title="Staked" value={nftsOnStaking.length.toString()} width="100%" />
                    </div>

                  </div>

                </div>
                <div className=' absolute -bottom-6 z-40 left-0 w-full flex items-center justify-center'>
                  <button className=' border border-pr hover:bg-pr bg-tr hover:text-tr   px-16 py-2 text-pr text-xl lg:text-3xl font-semibold' style={{display:"flex"}} onClick={handleClaim}>
                    {pendingClaim && <RotateImage src="https://upload.wikimedia.org/wikipedia/commons/d/d4/Spinner_font_awesome.svg" alt="renew" />}
                    {pendingClaim ? 'Process' : 'Claim PTC'}
                  </button>
                </div>
              </div>


            </div>
            <div className=' relative w-full flex items-center flex-col lg:flex-row mt-20 lg:mt-10 gap-20 lg:gap-0 '>
              <div className=' border h-full border-pr lg:w-1/2'>
                <h1 className=' py-1 text-center w-full bg-pr text-xl lg:text-3xl text-black' style={{ fontFamily: 'Adobe Garamond Pro Regular', }}>Not Staked</h1>
                <ActionContainer type="stake" reload={reload} setReload={setReload} />
              </div>
              <div className=' hidden lg:flex items-center justify-center  flex-col' style={{ width: "4%", }}>
                <div style={{ height: "1px", width: "100%" }} className=' bg-pr'>

                </div>

              </div>
              <div className=' border h-full border-pr lg:w-1/2'>
                <h1 className=' py-1 text-center w-full bg-pr text-xl lg:text-3xl text-black' style={{ fontFamily: 'Adobe Garamond Pro Regular', }}>Staked</h1>
                <ActionContainer type="unstake" reload={reload} setReload={setReload} />
              </div>

            </div>

          </div>

        </div>
      </div>

      <div className=' w-full flex items-center justify-center flex-col gap-2 pt-10 pb-20'>
        <img src="/images/logo.png" className=' w-32' alt="" />
        <div className=' flex items-center text-gray-400 gap-1 flex-col lg:flex-row'>
          � 2022 Crypto Platypus | <Link to="/" className=' border-b'>Terms & Conditions |</Link>  <Link to="/" className=' border-b'>Contact |</Link>  <Link to="/" className=' border-b'>Support</Link>
        </div>
      </div>
    </div>
  )
}

export default Staking