import styled from 'styled-components'
import WalletConnectButton from 'components/WalletConnectButton'

const StyledHeader = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 130px;
  }
  font-family: "SF Pro";
  width: 100%;
  height: 110px;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
`

const StyledLogo = styled.img`
  height: 80px;
`

const Header = () => {
  
  return (
    <div style={{padding:"0 10% 0 5%"}} className=' lg:absolute top-0 lg:top-6 left-0 w-full h-32  z-30 flex items-center   justify-center gap-4 lg:justify-between'>
      <a href="https://cryptoplatypus.io" target="_blank">
        <img className=' w-24 lg:w-36' style={{width:"7rem"}} src="images/logo.png" alt="logo" />
      </a>
      <WalletConnectButton />
    </div>
  )
}

export default Header