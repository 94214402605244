import React from 'react'
import styled from 'styled-components'
import { useWeb3Context } from 'hooks/useWeb3Context'

const StyledConnectButtonPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledAddressBox = styled.div`
  font-family: "SF Pro";
  font-size: 1.7rem;
  font-weight: 600;
  fill: #141414;
  color: #ca993b;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ca993b;
  padding: 10px 30px 10px 30px;
  margin-right: 10px;
`

const StyledConnectButton = styled.button`
  font-family: "SF Pro";
  font-size: 1.4rem;
  font-weight: 600;
  fill: #141414;
  color: #141414;
  background-color: #ca993b;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ca993b;
  padding: 5px 30px 5px 30px;
  margin-right: 10px;
  cursor: pointer;
  :hover {
		background-color: #231F16;
    color: #ca993b;
		cursor: pointer;
	}
`

const WalletConnectButton = () => {
  const web3Context = useWeb3Context()

  const displayAddress =  `${web3Context?.account.substring(0, 4)}...${web3Context?.account.substring(web3Context?.account.length - 4)}`
  
  return (
    <>
      { web3Context?.account ?
        <button className=' px-6 text-base text-white flex items-center flex-col lg:flex-row gap-4 py-2'>
          <p className=' text-lg lg:text-2xl px-8 py-2 border border-pr  font-semibold text-pr'>{displayAddress}</p>
          <button className=' px-8  font-semibold bg-pr py-2  hover:bg-tr hover:text-pr border border-pr text-black  text-base lg:text-2xl' onClick={web3Context?.disconnect}>
            Disconnect
          </button>
        </button>        
        :
        <button className=' px-8  font-semibold bg-pr py-2 text-black hover:bg-tr hover:text-pr border border-pr  text-base lg:text-2xl'  onClick={web3Context?.connectWallet}>Connect Wallet</button>
      }
    </>
  )
}

export default WalletConnectButton