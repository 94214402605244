import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { NFTData } from 'state/user'
import { useNFT, useStakedNFT, useNFTApprovalStatus, useApprovalStatus } from 'state/user/hooks'
import { useWeb3Context } from 'hooks/useWeb3Context'
import { callStakeNFTs, callUnstakeNFTs, callApproveForAll } from 'utils/calls'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledContainer = styled.div`
  color: white;
  width: 100%;
  height: 600px;
  margin-top: 10px;
  border: 1px solid #ca993b;
  box-shadow: 0 6px 20px #141414;
`

const StyledActionsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;


`

const StyledNFTArea = styled.div`
  height: 475px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin: 30px;
  overflow: overlay;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const StyledNFTImage = styled.img`
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  border: 1px solid #ca993b;
  margin: 20px auto;
`

const StyledCheckImage = styled.img`
  width: 40px;
  position: relative;
  top: -222px;
  right: -160px;
  cursor: pointer;
  display: flex;
  justify-content: end;
  background: #123123;
  text-align: right;
  align-items: end;
  border: 1px solid;
  border-radius: 40px;
  align-items: center;
  @media only screen and (max-width: 800px) {
    right: -200px;
  }
`

const StyledDetailArea = styled.div`
  width: 50px;
  position: relative;
  bottom: 100px;
  right: -150px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: #123123;
  text-align: center;
  align-items: center;
  border: 1px solid;
  border-radius: 40px;
  align-items: center;
  @media only screen and (max-width: 800px) {
    right: -200px;
  }
`

const StyledButton = styled.button`
align-items: center;
justify-content: center;
display: flex;
font-family: "SF Pro";
font-size: 25px;
font-weight: 600;
fill: #141414;
color: #141414;
background-color: #ca993b;
border-style: solid;
border-width: 1px 1px 1px 1px;
border-color: #ca993b;
padding: 11px 70px 11px 70px;
cursor: pointer;
:hover {
  background-color: #231F16;
  color: #ca993b;
  cursor: pointer;
}
`

const StyledItem = styled.div`
  cursor: pointer;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotateImage = styled.img`
  animation: ${rotate} 2s linear infinite;
  width: 23px;
  margin-left: 5px;
`

interface Props {
  type: string
  reload: boolean
  setReload: (reload: boolean) => void
}

const ActionContainer: React.FC<Props> = ({ type, reload, setReload }) => {
  const web3Context = useWeb3Context()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [changed, setChanged] = useState(false)
  const [approveProgress, setApproveProgress] = useState(false)
  const [pendingStake, setPendingStake] = useState(false)
  const [pendingUnStake, setPendingUnStake] = useState(false)

  useApprovalStatus(web3Context?.account, approveProgress)

  const nftsOnWallet: NFTData[] = useNFT()
  const nftsOnStaking: NFTData[] = useStakedNFT()

  const nfts = type === 'stake' ? nftsOnWallet : nftsOnStaking
  
  const approvalStatus = useNFTApprovalStatus()

  const handleSelect = (id: number) => {
    const items = selectedItems
    if (!items.includes(id)) {
      items.push(id)
      items.sort()
      setSelectedItems(items)
    } else {
      const filteredItems = items.filter((item) => item !== id )
      setSelectedItems(filteredItems)
    }
    setChanged(!changed)
  }

  const handleStake = async () => {
    if(selectedItems.length === 0) {
      toast.warn('Please select the NFTs you want to stake!', {
        position: "top-center",
        theme: "dark",
        });
      return
    }
    
    setPendingStake(true)    
    try {    
      if (web3Context?.provider && web3Context?.account) {
          await callStakeNFTs(web3Context?.provider, web3Context?.account, selectedItems)
          setSelectedItems([])
          setReload(!reload)
      } else {
        toast.warn('Check the wallet connection.', {
          position: "top-center",
          theme: "dark",
          });
      }
    } catch (error) {
      console.error(error)
    }
    setPendingStake(false)
  }

  const handleUnstake = async () => {
    if(selectedItems.length === 0) {
      toast.warn('Please select the NFTs you want to unstake!', {
        position: "top-center",
        theme: "dark",
        });
      return
    }

    setPendingUnStake(true)    
    try {    
      if (web3Context?.provider && web3Context?.account) {
        await callUnstakeNFTs(web3Context?.provider, web3Context?.account, selectedItems)
        setSelectedItems([])
        setReload(!reload)
      } else {
        toast.warn('Check the wallet connection.', {
          position: "top-center",
          theme: "dark",
          });
      }
    } catch (error) {
      console.error(error)
    }
    setPendingUnStake(false)
  }

  const handleApprove = async () => {
    setApproveProgress(true)
    try {
      if (web3Context?.provider && web3Context?.account) {
        await callApproveForAll(web3Context?.provider, web3Context?.account, true)
      } else {        
        toast.warn('Check the wallet connection.', {
          position: "top-center",
          theme: "dark",
          });
      }
    } catch {
    }
    setApproveProgress(false)
  }

  useEffect(() => {
    if (approvalStatus[0]) {
      setApproveProgress(false)
    }
  }, [approvalStatus, approvalStatus[0]])

  return (
    <div className=' relative w-full h-full'>
   
      {/* <ToastContainer /> */}

      <div className=' w-full h-scroll relative z-20 grid grid-cols-1 p-8 lg:grid-cols-3 gap-x-10 gap-y-5'>
        {nfts.map((nft, index) => {
          const isChecked = selectedItems.includes(nft.id)
          return (
            <div onClick={() => handleSelect(nft.id)} key={index} className=" border cursor-pointer border-pr relative" style={{ background: "#141414",height:"fit-content" }}>
              {/* <StyledNFTImage src={nft.image} alt={index.toString()} /> */}
              <img src="/images/sample.png" alt={index.toString()} />
            
    
              <div className=' absolute top-2 left-2 z-20 '>
              <img className=' w-7 h-7' src={isChecked ? "images/check.png" : "images/uncheck.png"} alt="check" />
              </div>
              <div className=' absolute bottom-2 right-2 z-20 '>
                <h1 className=' bg-pr px-2  rounded-full text-black font-bold text-base'> {nft.id}</h1>
              </div>
            </div>
          )
        })}
      </div>
   
      {type === "stake" ? 
      <div className=' absolute top-0 left-0 w-full h-full z-10'>
        <img style={{ opacity: "22%" }} src="/images/t1.jpg" className=' w-full h-full object-cover' alt="" />
      </div> 
      :
      <div className=' absolute top-0 left-0 w-full h-full z-10'>
        <img style={{ opacity: "22%" }} src="/images/t2.jpg" className=' w-full h-full object-cover' alt="" />
      </div>

      }
      
      <div className=' absolute  z-40 -bottom-6 left-0 w-full flex items-center justify-center'>
        {type === "stake" ?
          (!approvalStatus[0] ?
            <div className=' flex items-center justify-center w-full'>
              <button className=' border border-pr hover:bg-pr bg-tr hover:text-tr   px-16 py-2 text-pr text-xl lg:text-3xl font-semibold'  onClick={handleApprove}>
                {approveProgress ? 'Approving' : 'Approve'}
                {approveProgress && <RotateImage src="https://upload.wikimedia.org/wikipedia/commons/d/d4/Spinner_font_awesome.svg" alt="renew" />}
              </button>
            </div>
            :
            <div className=' flex items-center justify-center w-full'>
              <button className=' border border-pr hover:bg-pr bg-tr hover:text-tr   px-16 py-2 text-pr text-xl lg:text-3xl font-semibold' style={{display:"flex"}} onClick={handleStake}>
                {pendingStake && <RotateImage src="https://upload.wikimedia.org/wikipedia/commons/d/d4/Spinner_font_awesome.svg" alt="renew" />}
                {pendingStake ? 'Process' : 'Stake'}
              </button>
            </div>
          )
          :
          <div className=' flex items-center justify-center w-full'>
            <button className=' border border-pr hover:bg-pr bg-tr hover:text-tr   px-16 py-2 text-pr text-xl lg:text-3xl font-semibold' style={{display:"flex"}} onClick={handleUnstake}>
              {pendingUnStake && <RotateImage src="https://upload.wikimedia.org/wikipedia/commons/d/d4/Spinner_font_awesome.svg" alt="renew" />}
              {pendingUnStake ? 'Process' : 'Unstake'}
            </button>
          </div>
        }
      </div>
    </div>
  )
}

export default ActionContainer